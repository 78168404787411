Liferay.Workflow = {
	ACTION_PUBLISH: 1,

	ACTION_SAVE_DRAFT: 2,

	STATUS_ANY: -1,

	STATUS_APPROVED: 0,

	STATUS_DENIED: 4,

	STATUS_DRAFT: 2,

	STATUS_EXPIRED: 3,

	STATUS_PENDING: 1
};